import { DocumentStatus } from '../../v2/models/activity-events/document-activity-event.model';
import { DocumentTableType } from './document-table-type';
import { TableIndexState } from '@geneious/nucleus-api-client';

export interface DocumentServiceGetTableResponse {
  data: DocumentServiceTableInfoMap;
}

export interface DocumentServiceTableInfoMap {
  [type: string]: DocumentServiceTableInfo;
}

export interface DocumentServiceTableInfo {
  columns: DocumentServiceColumn[];
  columnGroups?: DocumentServiceTableColumnGroups;
  createdAt: string;
  displayName: string;
  fileLocation: {
    blobName: string;
    documentID: string;
    kind: DocumentServiceFileLocationKind;
  };
  joins: DocumentServiceTableInfoJoins;
  kind: DocumentServiceTableKind;
  status: DocumentStatus;
  lastModifiedAt: string;
  metadata?: TableMetadata; // Optional as older docs may not have it.
  indexState?: TableIndexState;
  tableType: DocumentTableType;
}

export interface DocumentServiceTableColumnGroups {
  [type: string]: DocumentServiceTableColumnGroup;
}

export interface DocumentServiceTableColumnGroup {
  name: string;
  columns: string[];
}

export interface DocumentServiceColumn {
  dataType: {
    kind: DocumentServiceColumnDataType;
  };
  name: string;
  originalName: string;
  displayName: string;
  metadata?: {
    description?: string;
  };
}

export enum DocumentServiceColumnDataType {
  Integer = 'Integer',
  String = 'String',
  Array = 'Array',
  ListSet = 'ListSet',
  Double = 'Double',
}

export interface DocumentServiceTableInfoJoins {
  [type: string]: DocumentServiceTableInfoJoin;
}

export interface DocumentServiceTableInfoJoin {
  baseColumn: string;
  comparison: {
    kind: string;
    caseSensitive: boolean;
    ignoreLeadingSpaces: boolean;
    ignoreLeadingZeros: boolean;
  };
  caseSensitive: boolean;
  ignoreLeadingSpaces: boolean;
  ignoreLeadingZeros: boolean;
  kind: string;
  joinedAt: string;
  joinedColumn: string;
}

export interface TableMetadata {
  // Matches what is defined here: https://bitbucket.org/dotmatics/geneious/src/master/PrivateAPI/src/com/biomatters/geneious/privateApi/biologics/BiologicsTableMetaData.java
  tableContentType:
    | 'AllSequences'
    | 'ChainCombinations'
    | 'AnnotationRates'
    | 'ClustersSummary'
    | 'Clusters'
    | 'ComparisonSummary'
    | 'ComparisonCluster'
    | 'ComparisonLengths'
    | 'AminoAcidFrequenciesByPosition'
    | 'RegionLengths'
    | 'CodonUsage'
    | 'CodonUsageByPosition'
    | 'ClusterDiversity'
    | 'GeneHeatMap'
    | 'SingleCloneReportProcessingSteps'
    | 'SingleCloneReportByBarcodeOrFile';
  analysisType:
    | 'AntibodyAnnotator'
    | 'SingleClone'
    | 'Comparisons'
    | 'NgsAnnotator'
    | 'GenericAnnotator';
  displayName: string;
  idName: string;
  clusters?: {
    usedBeforeCollapsingFrequencies: boolean;
    columnName: string;
    clusterGroups: {
      clusterMethod:
        | 'ExactNucleotide'
        | 'ExactAminoAcid'
        | 'IdentityNucleotide'
        | 'IdentityAminoAcid'
        | 'IdentityNucleotideByCount'
        | 'IdentityAminoAcidByCount'
        | 'SimilarityAminoAcid'
        | 'Gene'
        | 'FeatureName'
        | 'TemplateName'
        | 'LinkerName';
      percentage: number;
      regionOrGenes: {
        dataType: 'Nucleotide' | 'AminoAcid' | 'GeneNames' | 'FeatureNames';
        chain: 'Heavy' | 'Light' | null;
        name: string;
        chainName: string | null;
        annotationType: string;
        annotationName: string | null;
        annotationGeneRegion: string | null;
      }[];
    }[];
    containsGeneNames: boolean;
    containsNucleotides: boolean;
    containsAminoAcids: boolean;
    containsAnyInexactClusters: boolean;
    containsMultipleRegions: boolean;
  } | null;
  chainNamesPossiblyPresent: string[];
}

export enum DocumentServiceFileLocationKind {
  NucleusBlob,
}

export enum DocumentServiceTableKind {
  'Inferred',
  'Defined',
}

export interface DocumentTable extends DocumentServiceTableInfo {
  documentID: string;
  name: string;
}

export function tableIsQueryable(table: DocumentTable): boolean {
  return table.indexState === TableIndexState.Open || table.indexState === TableIndexState.Closed;
}
