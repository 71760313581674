import {
  DocumentTableIndexStateStateError,
  DocumentTableState,
  DocumentTableUIIndexState,
  DocumentTableUIIndexStates,
  IndexStateEnum,
} from './document-table-state';
import {
  fetchDocumentTables,
  fetchDocumentTablesFailure,
  fetchDocumentTablesSuccess,
  refreshTables,
  reIndexDocumentTableFailure,
  reIndexDocumentTableProgressUpdate,
  reIndexDocumentTables,
  reIndexDocumentTableSuccess,
  restoreDocumentTable,
  restoreDocumentTableFailure,
  restoreDocumentTableProgressUpdate,
  restoreDocumentTableSuccess,
} from './document-table-state.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { createReducer, on } from '@ngrx/store';
import { DocumentStatusKind } from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';

export const adapter = createEntityAdapter<DocumentTable>({
  selectId: (table) => generateDocumentTableStateID(table.documentID, table.name),
});
export const initialState: DocumentTableState = {
  ...adapter.getInitialState(),
  fetchingState: {},
  documentTableIndexStates: {},
  documentSequencesCount: {},
};

export const documentTableStateReducer = createReducer(
  initialState,
  on(refreshTables, (state, { documentID }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: true } },
  })),
  on(fetchDocumentTables, (state, { documentID, numberOfSequences }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: true } },
    documentSequencesCount: {
      ...state.documentSequencesCount,
      [documentID]: numberOfSequences,
    },
  })),
  on(fetchDocumentTablesSuccess, (state, { documentID, tables }) => ({
    ...adapter.setMany(
      tables,
      adapter.removeMany((entity) => entity.documentID.startsWith(documentID), state),
    ),
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      ...getFetchedIndexingStatuses(documentID, tables),
    },
    fetchingState: { [documentID]: { fetching: false } },
  })),
  on(fetchDocumentTablesFailure, (state, { documentID, reason }) => ({
    ...state,
    fetchingState: { [documentID]: { fetching: false, error: reason } },
  })),
  on(restoreDocumentTable, (state, { documentID, tableName }) => ({
    ...state,
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      [generateDocumentTableStateID(documentID, tableName)]: {
        tableName,
        currentIndexState: IndexStateEnum.RESTORING,
        progress: 0,
      },
    },
  })),
  on(reIndexDocumentTables, (state, { documentID, tableNames }) => ({
    ...state,
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      ...generateReindexingTableStatuses(documentID, tableNames),
    },
  })),
  on(restoreDocumentTableProgressUpdate, (state, { documentID, tableName, progress }) => ({
    ...state,
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      [generateDocumentTableStateID(documentID, tableName)]: {
        tableName,
        currentIndexState: IndexStateEnum.RESTORING,
        progress,
      },
    },
  })),
  on(
    reIndexDocumentTableProgressUpdate,
    (state, { documentID, tableNames, progress, indexingJobID }) => ({
      ...state,
      documentTableIndexStates: {
        ...state.documentTableIndexStates,
        ...getReIndexDocumentTableProgressUpdates(documentID, tableNames, progress, indexingJobID),
      },
    }),
  ),
  on(reIndexDocumentTableSuccess, (state, { documentID, tableNames }) => ({
    ...state,
    ...adapter.updateMany(
      tableNames.map((tableName) => {
        return {
          id: generateDocumentTableStateID(documentID, tableName),
          changes: { indexState: 'open' },
        };
      }),
      state,
    ),
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      ...getReIndexDocumentTableSuccesses(documentID, tableNames),
    },
  })),
  on(restoreDocumentTableSuccess, (state, { documentID, tableName }) => ({
    ...state,
    ...adapter.updateOne(
      {
        id: generateDocumentTableStateID(documentID, tableName),
        changes: { indexState: 'open' },
      },
      state,
    ),
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      [generateDocumentTableStateID(documentID, tableName)]: {
        tableName,
        currentIndexState: IndexStateEnum.OPEN,
        progress: 100,
      },
    },
  })),
  on(restoreDocumentTableFailure, (state, { documentID, tableName, error }) => ({
    ...state,
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      [generateDocumentTableStateID(documentID, tableName)]: {
        ...state.documentTableIndexStates[generateDocumentTableStateID(documentID, tableName)],
        currentIndexState: IndexStateEnum.RESTORING_ERROR,
        error,
      },
    },
  })),
  on(reIndexDocumentTableFailure, (state, { documentID, tableNames, error }) => ({
    ...state,
    documentTableIndexStates: {
      ...state.documentTableIndexStates,
      ...getReIndexDocumentTableFailures(documentID, tableNames, error),
    },
  })),
);

export function generateDocumentTableStateID(documentID: string, tableName: string) {
  return documentID + tableName;
}

export function generateReindexingTableStatuses(documentID: string, tableNames: string[]) {
  let status: DocumentTableUIIndexStates = {};
  for (const tableName of tableNames) {
    status[generateDocumentTableStateID(documentID, tableName)] = {
      tableName,
      currentIndexState: IndexStateEnum.REINDEXING,
      progress: 0,
    };
  }
  return status;
}

export function getFetchedIndexingStatuses(documentID: string, tables: DocumentTable[]) {
  let states: DocumentTableUIIndexStates = {};
  for (const table of tables) {
    states[generateDocumentTableStateID(documentID, table.name)] = deriveCurrentIndexState(table);
  }
  return states;
}

/**
 * Derive the current indexing state for UI based on the table state. This does not set the progress as the nucleus does not know the actual job progress, but it would
 * automatically be updated as UI start to receive the job progress events
 * @param table
 */
function deriveCurrentIndexState(table: DocumentTable): DocumentTableUIIndexState {
  if (table?.status?.kind === DocumentStatusKind.INDEXING) {
    return {
      tableName: table.name,
      currentIndexState: IndexStateEnum.REINDEXING,
      indexingJobID: table.status.jobID,
    };
  } else {
    return {
      tableName: table.name,
      currentIndexState: table.indexState as IndexStateEnum,
    };
  }
}

export function getReIndexDocumentTableSuccesses(documentID: string, tableNames: string[]) {
  let states: DocumentTableUIIndexStates = {};
  for (const tableName of tableNames) {
    states[generateDocumentTableStateID(documentID, tableName)] = {
      tableName,
      currentIndexState: IndexStateEnum.OPEN,
    };
  }
  return states;
}

export function getReIndexDocumentTableFailures(
  documentID: string,
  tableNames: string[],
  error: DocumentTableIndexStateStateError,
) {
  let status: DocumentTableUIIndexStates = {};
  for (const tableName of tableNames) {
    status[generateDocumentTableStateID(documentID, tableName)] = {
      tableName,
      currentIndexState: IndexStateEnum.REINDEXING_ERROR,
      error,
    };
  }
  return status;
}

export function getReIndexDocumentTableProgressUpdates(
  documentID: string,
  tableNames: string[],
  progress: number,
  indexingJobID: string,
) {
  let states: DocumentTableUIIndexStates = {};
  for (const tableName of tableNames) {
    states[generateDocumentTableStateID(documentID, tableName)] = {
      tableName,
      currentIndexState: progress === 100 ? IndexStateEnum.OPEN : IndexStateEnum.REINDEXING,
      progress: progress,
      indexingJobID,
    };
  }
  return states;
}
