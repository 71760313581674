import { DocumentTableStore, IndexStateEnum } from './document-table-state';
import { createSelector } from '@ngrx/store';
import { adapter, generateDocumentTableStateID } from './document-table-state.reducer';
import { DocumentStatusKind } from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';

export const selectDocumentTableState = (state: DocumentTableStore) => {
  return state.documentTableState;
};

export const documentTableUIIndexStatesSelector = createSelector(
  selectDocumentTableState,
  (state) => state.documentTableIndexStates,
);

export const selectDocumentTables = (documentID: string) =>
  createSelector(
    adapter.getSelectors().selectAll,
    // TODO Improve this normalization, I shouldn't need to use a filter.
    (tables) => tables.filter((table) => table.documentID === documentID),
  );

export const selectDocumentTable = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTableState,
    (state) => state.entities[generateDocumentTableStateID(documentID, tableName)],
  );

export const selectDocumentTableUIIndexState = (documentID: string, tableName: string) =>
  createSelector(documentTableUIIndexStatesSelector, (documentTableUIIndexStates) => {
    return (
      documentTableUIIndexStates[generateDocumentTableStateID(documentID, tableName)] ?? {
        currentIndexState: IndexStateEnum.OPEN,
        tableName,
      }
    );
  });

export const selectDocumentTableUIIndexStatesForTables = (
  documentID: string,
  tableNames: string[],
) =>
  createSelector(documentTableUIIndexStatesSelector, (documentTableUIIndexStates) =>
    Object.keys(documentTableUIIndexStates)
      .filter((stateID) =>
        tableNames
          .map((tableName) => generateDocumentTableStateID(documentID, tableName))
          .includes(stateID),
      )
      .map((stateID) => documentTableUIIndexStates[stateID]),
  );

export const selectDocumentTableIndexState = (documentID: string, tableName: string) =>
  createSelector(selectDocumentTable(documentID, tableName), (state) => state.indexState);

export const selectDocumentTableUIIndexStates = (documentID: string) =>
  createSelector(documentTableUIIndexStatesSelector, (documentTableUIIndexStates) =>
    Object.keys(documentTableUIIndexStates)
      .filter((stateID) => stateID.startsWith(documentID))
      .map((stateID) => documentTableUIIndexStates[stateID]),
  );

export const selectDocumentTablesFetchingState = (documentID: string) =>
  createSelector(selectDocumentTableState, (state) => state.fetchingState[documentID]);

export const selectDocumentTableSequencesCount = (documentID: string) =>
  createSelector(selectDocumentTableState, (state) => state.documentSequencesCount[documentID]);

export const restoreRequiredTableSelector = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTable(documentID, tableName),
    selectDocumentTableUIIndexState(documentID, tableName),
    (documentTable, uiIndexState) => {
      if (documentTable && uiIndexState.currentIndexState !== IndexStateEnum.RESTORING) {
        return documentTable;
      }
    },
  );

/**
 * select document table if that needs to be re-indexed
 * @param documentID
 * @param tableName
 */
export const reIndexingRequiredSelector = (documentID: string, tableName: string) =>
  createSelector(
    selectDocumentTable(documentID, tableName),
    selectDocumentTableUIIndexState(documentID, tableName),
    (documentTable, uiIndexState) => {
      if (
        documentTable &&
        documentTable.status.kind === DocumentStatusKind.IDLE &&
        uiIndexState.currentIndexState === IndexStateEnum.ABSENT
      ) {
        return documentTable;
      }
    },
  );
