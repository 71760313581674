import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core.store';
import { DocumentTable } from '../../../../nucleus/services/documentService/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface DocumentTableState extends EntityState<DocumentTable> {
  fetchingState: FetchingStates;
  documentTableIndexStates: DocumentTableUIIndexStates;
  documentSequencesCount: DocumentSequencesCountState;
}

export interface FetchingStates {
  [documentID: string]: FetchingState;
}

export interface FetchingState {
  fetching: boolean;
  error?: FetchingStateError;
}

export interface DocumentSequencesCountState {
  [documentID: string]: number;
}

export enum IndexStateEnum {
  ABSENT = 'absent',
  OPEN = 'open',
  ARCHIVED = 'archived',
  CLOSED = 'closed',
  RESTORING = 'restoring',
  REINDEXING = 're-indexing',
  RESTORING_ERROR = 'restoring-error',
  REINDEXING_ERROR = 're-indexing-error',
}

export type FetchingStateError =
  | 'No tables exist on this document'
  | 'Failed to fetch document tables';
export type DocumentTableIndexStateStateError =
  | 'Document table index state is absent'
  | 'Failed to re-index document table'
  | 'Failed to restore document table';

export interface DocumentTableUIIndexStates {
  [documentIDTableName: string]: DocumentTableUIIndexState;
}

export const ARCHIVE_INDICES_AFTER = '30 days';
export const DELETE_INDICES_AFTER = '12 months';

export interface DocumentTableUIIndexState {
  tableName: string;
  currentIndexState: IndexStateEnum;
  progress?: number;
  indexingJobID?: string;
  error?: DocumentTableIndexStateStateError;
}

export interface DocumentTableStore extends AppState {
  documentTableState: DocumentTableState;
}

export function whenTablesAreFetched(documentID: string) {
  return function (observable: Observable<DocumentTableState>) {
    return observable.pipe(
      filter((state) => {
        return state.fetchingState[documentID] && !state.fetchingState[documentID].fetching;
      }),
    );
  };
}
